<template>
    <div class="report bar-graph" :class="{'horizontal' : horizontal}">
        <div class="bars">
            <div v-for="bar, i in bars" :key="i" :style="{height: horizontal ? '' : `${bar[2]}%`, width: horizontal ? `${bar[2]}%` : ''}">
                <span>{{bar[0]}} ({{bar[1]}})</span>
                <span class="back">{{bar[0]}} ({{bar[1]}})</span>
            </div>
        </div>
        <div class="keys" v-if="!horizontal">
            <div v-for="bar, i in bars" :key="i">
                {{bar[0]}} ({{bar[1]}})
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';

export default {
    components: {},
    props: ['horizontal', 'collection', 'prop', 'groupby', 'adjust', 'key'],
    setup(props){
        const buildBars = {};
        const total = ref(0);
        
        props.collection.forEach((x) => {
            if (x[props.groupby] !== undefined){
                if (!Object.hasOwnProperty.call(buildBars, x[props.groupby])){
                    buildBars[x[props.groupby]] = 0;
                }
                if (props.prop === 'count'){
                    buildBars[x[props.groupby]]++;
                    total.value ++;
                } else {
                    buildBars[x[props.groupby]] += x[props.prop];
                    total.value += x[props.prop];
                }
            }
        });

        const bars = Object.entries(buildBars).sort((x, y) => {
            return y[1] - x[1];
        });

        var highest = bars[0][1];
        const lowest = props.adjust ? bars[bars.length - 1][1] * props.adjust : 0;

        if (lowest){
            highest -= lowest;
        }

        bars.forEach((x, i) => {
            bars[i][2] = (bars[i][1] - lowest) / highest * 100
        });            

        return {bars}
    },
}
</script>

<style lang="scss">

@import "../../assets/scss/variables.scss";

.bar-graph{
    height: 100%;
    display: flex;
    align-items: flex-end;
    
    .bars{
        height: 100%;
        display: flex;
        align-items: flex-end;

        >div{
            width: 25px;
            margin-right: $space-wide;
            background: nth($grays, 7);
            position: relative;
            z-index: 50;
            
            &:first-child{
                background: $brand;
            }

            @for $i from 1 through 4{
                &:nth-child(#{$i + 1}){
                    background: nth($grays, $i + 3);
                }
            }

            >span{
                text-transform: capitalize;
                position: absolute;
                bottom: 0;
                left: -2px;
                display: block;
                color: #FFFFFF;
                white-space: nowrap;
                height: 100%;
                overflow: hidden;
                padding-top: $space-narrow;
                text-shadow: 0 0 4px rgba(0,0,0,1);
                background: inherit;

                &.back{
                    background: none;
                    height: auto;
                    color: $font-color;
                    z-index: -1;
                    text-shadow: none;
                }
            }

        }
    }
    &:not(.horizontal){
        .bars{
            >div{
                >span {
                    writing-mode: vertical-lr;
                    transform: rotate(180deg);

                }
            }
        }
    }
    .keys {
        margin-left: $space-wide;
        text-transform: capitalize;
        margin-bottom: -3px;

        >div {
            &::before{
                content: "";
                display: inline-block;
                height: 20px;
                width: 20px;
                background: nth($grays, 7);
                vertical-align: middle;
                margin: -2px $space-standard 2px 0;
            }
                        
            &:first-child{
                &::before{
                    background: $brand;
                }
            }

            @for $i from 1 through 4{
                &:nth-child(#{$i + 1}){
                    &::before{
                        background: nth($grays, $i + 3);
                    }
                }
            }
        }
    }

    &.horizontal{
        justify-content: stretch;
        align-items: flex-end;

        .bars{
            flex: 1 1 auto;
            display: block;

            >div {
                margin-top: $space-wide;
                height: 25px;

                >span{
                    padding-top: 0;
                    padding-left: $space-narrow;
                    line-height: 25px;    
                }
            }
        }
    }
}

</style>