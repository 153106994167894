<template>
    <div>
        <header class="page-header">
            <div>           
                <client-account-header></client-account-header>
                <h2>Account Overview</h2>
            </div>
        </header>
        <div class="components" v-if="loaded">
            <div class="component" style="height: 300px">
                <h4>Active Trailers by Wall Type</h4>
                <bar-graph :collection="equipmentData" groupby="wallType" prop="count"></bar-graph>
            </div>
            <div class="component" style="height: 300px">
                <h4>Active Trailers by Wall Type (adj 50%)</h4>
                <bar-graph :collection="equipmentData" groupby="wallType" prop="count" :adjust="0.5"></bar-graph>
            </div>
            <div class="component" style="width: 400px">
                <h4>Active Trailers by Door Type (adj 90%)</h4>
                <bar-graph :collection="equipmentData" groupby="doorType" prop="count" :horizontal="true" :adjust="0.9"></bar-graph>
            </div>
        </div>
        <img src="@/assets/images/placeholderreports.jpg" alt="Star Leasing" style="display: block; margin:0 auto;" />
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';

import ClientAccountHeader from '../components/clientAccounts/ClientAccountHeader.vue';
import BarGraph from '../components/reports/BarGraph.vue';
import EquipmentModel from '../models/EquipmentModel';
import axiosInstance from '../connections/AxiosInstance';

export default {
    components: {ClientAccountHeader, BarGraph},
    props: [],
    setup(){

        const loaded = ref(false);
        let p1 = axiosInstance.getAll(EquipmentModel);

        Promise.all([p1]).then(() => {
            loaded.value = true;
        });
        return {loaded};

    },
    computed: {
        equipmentData(){
            return this.$store.state.AppData[EquipmentModel.name];
        }
    }
}
</script>